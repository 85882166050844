<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Country <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-autocomplete
              v-model="item.countryId"
              :items="countries"
              outlined
              dense
              flat
              background-color="white"
            ></v-autocomplete>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>{{ getCountryName() }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model="item.name"
              required
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>{{ item.name }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Code <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model="item.code"
              :counter="5"
              required
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>{{ item.code }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Symbol <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model="item.symbol"
              outlined
              dense
              flat
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>{{ item.symbol }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Buy Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model.number="item.toIDR"
              type="number"
              outlined
              dense
              flat
              disabled
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>Rp. {{ formatPrice(item.toIDR) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Middle Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model.number="item.middlePrice"
              type="number"
              outlined
              dense
              flat
              disabled
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>Rp. {{ formatPrice(item.middlePrice) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Sell Price (Rupiah)</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-text-field
              v-model.number="item.sellPrice"
              type="number"
              outlined
              dense
              flat
              disabled
              background-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>Rp. {{ formatPrice(item.sellPrice) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Bank Reference</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="!edit">
            <v-select
              v-model="item.bankReference"
              :items="banks"
              outlined
              dense
              flat
              background-color="white"
            ></v-select>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0" v-if="edit" align-self="center">
            <span>{{ item.bankReference }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateCurrency">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editForm">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="deleteCurrency">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "currency-detail",
  props: {
    id: Number,
  },
  data: () => ({
    edit: true,
    banks: ["BCA", "BNI", "BRI", "MANDIRI", "BTPN", "PANIN", "BUKOPIN", "CIMB"],
    item: {},
  }),
  computed: {
    ...mapState("currency", ["currency"]),
    ...mapState("country", ["countries"]),
  },
  methods: {
    async getCurrencyDetail() {
      await this.$store.dispatch("currency/getById", this.id).then(response => {
        this.item = response.data;
      });
    },
    async updateCurrency() {
      await this.$store.dispatch("currency/update", this.item);
    },
    async deleteCurrency() {
      await this.$store.dispatch("currency/delete", this.id);
    },
    async dropDownCountry() {
      await this.$store.dispatch("country/getAll");
    },
    back() {
      this.$router.go(-1);
    },
    editForm() {
      this.edit = false;
    },
    cancel() {
      this.edit = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getCountryName() {
      let result = this.countries.filter(x => {
        if (x.value === this.item.countryId) {
          return x;
        }
      })[0];
      return result ? result.text : "";
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getCurrencyDetail(), this.dropDownCountry()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
